import { createStore } from "redux";

const initalState = {
    sliderValue: localStorage.getItem("sliderValue") ? parseInt(localStorage.getItem("sliderValue"))  : 15
}


const reducer = (state = initalState, action)=>{
    switch (action.type) {
        case 'SLIDER_CHANGE':
            return Object.assign({}, state, { sliderValue: action.value })    
        default:
            return state;
    }
}

const store = createStore(reducer)

export default store; 