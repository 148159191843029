import React, { Component } from 'react';
import { Scheduler,   SchedulerItem,  SchedulerItemContent, WorkWeekView } from '@progress/kendo-react-scheduler';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { IntlProvider, IntlService, load } from '@progress/kendo-react-intl';
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import days from './days.json';
import axios from 'axios';
import {
  ZonedDate,
  getDate,
  addDays,
  MS_PER_HOUR
} from '@progress/kendo-date-math';
import { displayDate } from './events-utc';
import {  startOfWeek } from 'date-fns'
import { CustomHeader } from "./header";
import '../css/scheduler.css';
import ProgramContext from './ProgramContext';
import Programdata from './Programdata';
import { CustomSlot } from "./CustomSlot";
import store from '../store';

load(
  likelySubtags,
  currencyData,
  weekData,
  frLocalCurrency,
  frNumbers,
  frCaGregorian,
  frDateFields
);
const baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
class OffsetDate extends Date {
  // constructor(date: Date) {
  //   super(date);
  // }

  getHours() {
    const current = super.getHours();
    switch (current) {
      case 0:
        return 24;
      case 1:
        return 25;
      case 2:
        return 26;
      case 3:
        return 27;
      case 4:
        return 28;
      case 5:
        return 29;
      default:
        return current;
    }
  }
}

class CustomIntlService extends IntlService {
  // constructor(props) {
  //   super(props);
  // }
  firstDay() {
    return 1;
  }
  formatDate(value, format) {
    const offsetDate = new OffsetDate(value);
    if (format === 't') {
      return super.formatDate(offsetDate, 'HH:mm');
    } else {
      return super.formatDate(value, format);
    }
  }
}

class CustomIntlProvider extends IntlProvider {
  constructor(props) {
    super(props);
  }
  getIntlService() {
    return new CustomIntlService(this.props.locale);
  }
}

function toUTCDateTime(localDate): Date {
  return new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds(),
      localDate.getMilliseconds()
    )
  );
}

async function  getSchedulerData (service){
  await axios.get(`${baseUrl}/${service}`)
        .then((response: any) => {
          if(response.data.error){
              console.log(response)
          }else{        
            localStorage.setItem(service, JSON.stringify(response.data.data));
          }
          
      }).catch((err) => console.log(err.message)); 
}

class Home extends Component<any, any> {
  static contextType = ProgramContext;

  private myRef;
    constructor(props) {
      super(props);
      this.myRef = React.createRef<ExcelExport>();
      this.state = {
      }

      store.subscribe(() => { 
        this.setState({
          sliderValue: store.getState().sliderValue
        });
      });
  }


   customDateRange = ({ date, intl, timezone, numberOfDays }) => {
     const selectedSupport = localStorage.getItem('selectedSupport');
     const supports = JSON.parse(localStorage.getItem('support')) || [];
     const startDay = supports.filter((support)=>{
         return support.name === selectedSupport
     }).map((support)=>{return support.startDay})
    const startWeekDate = startOfWeek(date, {weekStartsOn: days[startDay]});
    //  console.log(startWeekDate)
    const normalized = ZonedDate.fromLocalDate(startWeekDate, timezone);
    // Add an offset of 5 hours
    const firstDay = new Date(getDate(normalized).getTime() + 6 * MS_PER_HOUR);
    const lastDay = addDays(firstDay, numberOfDays);
    const zonedStart = ZonedDate.fromUTCDate(toUTCDateTime(firstDay), timezone);
    const zonedEnd = ZonedDate.fromUTCDate(toUTCDateTime(lastDay), timezone);
    const start = new Date(zonedStart.getTime());
    const end = new Date(zonedEnd.getTime());
    return {
      start,
      end,
      zonedStart,
      zonedEnd
    };
  };


  exportExcel = () => {
    if (this.myRef.current) {
      this.myRef.current.save();
    }
  };
  getData = ()=>{
    const localProgram = new Programdata(JSON.parse(localStorage.getItem('program')));
   
    const program = JSON.parse(localStorage.getItem('program')) ? localProgram.getProgram() : [];
    return program;
  }

  render(){

    const storedState  = store.getState()
    const duration = storedState.sliderValue;
    const programData = this.getData();
    // console.log(programData)
    getSchedulerData('support')
    // getSchedulerData('program')
    getSchedulerData('category')
    // const supports = JSON.parse(localStorage.getItem('support')) || [];
    // const data = supports.map((support)=>{
    //     return support.name;
    // })
    // console.log(this.state.sliderValue)

    return (
      <CustomIntlProvider locale={'en'}>
          {/* <button className="k-button" onClick={this.exportExcel}>
            Export to Excel
          </button> */}
          <ExcelExport 
          data={programData} 
          fileName="Products.xlsx"
          ref={this.myRef}
          >
            <ExcelExportColumn
              field="title"
              title="Title"
              cellOptions={{ textAlign: "left" }}
              width={200}
            />
            <ExcelExportColumn
              field="start"
              title="Date"
              cellOptions={{ textAlign: "left" }}
              width={200}
            />
            <ExcelExportColumn
              field="start"
              title="Start"
              width={200}
              cellOptions={{ textAlign: "left" }}
            />
            <ExcelExportColumn
              field="end"
              title="End"
              width={200}
              cellOptions={{ textAlign: "left" }}
            />
        </ExcelExport>        

          <Scheduler 
          data={programData}
          defaultDate={displayDate}
          item={CustomItem}
          footer={(props) =>
             <React.Fragment />
          }
          
          header={(props) =>
              <CustomHeader {...props} />
          }
          >
          <WorkWeekView
            title=" "
            numberOfDays={7} 
            slotDivisions={1} 
            slotDuration={duration}  
            dateRange={this.customDateRange} 
            viewItem={CustomSlot}
            showWorkHours={false} 
          />
        </Scheduler>
      </CustomIntlProvider>
    );
  }

}

const CustomItem = props => {
  return (
    <SchedulerItem {...props}
    range="25"
      style={{
        ...props.style,
        background: `${props.dataItem.color}`,
        fontSize: `${props.dataItem.size}`,
      }}
    >
    <SchedulerItemContent title={props.dataItem.title +' ' + props.dataItem.support }>
      <span>
        {props.dataItem.title}
      </span>
      {/* <span className="ml-3 k-icon k-i-pencil"></span> */}
      <br/>
      <span>
        {props.dataItem.support}
      </span>
      <br/>
      <span>
        episode
      </span>
    </SchedulerItemContent>
    </SchedulerItem>
  );
};

export default Home