import * as React from 'react';
import { SchedulerViewItem, SchedulerViewItemHandle } from "@progress/kendo-react-scheduler";
declare global {
    interface Window {
        ResizeObserver?: any;
    }
    interface document {
        querySelector?: any;
    }
  } 

const getRect = (el) => {
  if (!el) {
    return {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    };
  }

  const top = el.offsetTop;
  const left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;
  return {
    top,
    left,
    width,
    height,
  };
};

export const CustomSlot = (props) => {
//   const item = React.createRef();
  const item = React.useRef<SchedulerViewItemHandle>(null);
  const [display, setDisplay] = React.useState(true);
  const [visible, setVisible] = React.useState(false);

  const reflow = () => {
    const firstSlot = props.slots && props.slots.length ? props.slots[0] : null;

    if (props.slots.length === 0 || !firstSlot || !firstSlot._ref.current) {
      setDisplay(false);
      return;
    }

    const slotRect = getRect(firstSlot._ref.current.element);
    const pxPerMilisecond =
      (props.vertical ? slotRect.height : slotRect.width) /
      (firstSlot.end.getTime() - firstSlot.start.getTime());
    const offset =
      (props.start.getTime() - firstSlot.start.getTime()) * pxPerMilisecond;
    const total =
      (props.end.getTime() - props.start.getTime()) * pxPerMilisecond;
    const element = item.current && item.current.element;
    const itemRect = getRect(element);

    if (!element) {
      return;
    }

    window.requestAnimationFrame(() => {
      if (props.vertical) {
        element.style.height = `${total + (offset < 0 ? offset : 0)}px`;
        element.style.top = `${itemRect.top + (offset < 0 ? 0 : offset)}px`;
      } else {
        element.style.width = `${total}px`;
        element.style.left = `${itemRect.left + (offset < 0 ? 0 : offset)}px`;
      }

      setVisible(true);
    });
  };

  React.useEffect(() => {
    reflow();
    const scheduler = document.querySelector('.k-scheduler');
    if (!scheduler) {
      return;
    }
    const observer = new window.ResizeObserver(reflow);
    observer.observe(scheduler);

    return () => {
      observer.disconnect();
    };
  });
  return (
    <SchedulerViewItem
      {...props}
      ref={item}
      style={{
        visibility: visible ? undefined : 'hidden',
        display: display ? undefined : 'none',
        ...props.style,
      }}
    />
  );
};

































// import * as React from "react";
// import { SchedulerViewItem, SchedulerViewItemHandle, SchedulerViewItemProps } from "@progress/kendo-react-scheduler";

// export const CustomSlot = (props: SchedulerViewItemProps) => {
//     const item = React.useRef<SchedulerViewItemHandle>(null);

//     const [display, setDisplay] = React.useState(true);
//     const [visible, setVisible] = React.useState(false);

//     const firstSlot = props.slots && props.slots.length ? props.slots[0] : null;

//     const reflow = () => {
//         if (props.slots.length === 0 || !firstSlot || !firstSlot._ref.current) {
//             setDisplay(false);
//             return;
//         }
//         const slotRect = getRect(firstSlot._ref.current.element);
//         const pxPerMilisecond =
//             (props.vertical ? slotRect.height : slotRect.width) /
//             (firstSlot.end.getTime() - firstSlot.start.getTime());

//         const offset =
//             (props.start.getTime() - firstSlot.start.getTime()) * pxPerMilisecond;

//         const total =
//             (props.end.getTime() - props.start.getTime()) * pxPerMilisecond;

//         const element = item.current && item.current.element;
//         const itemRect = getRect(element);
//         console.log(offset)
//         if(!element) { return; }

//         if (props.vertical) {
//             element.style.height = `${total + (offset < 0 ? offset : 0)}px`;
//             element.style.top = `${itemRect.top + (offset < 0 ? 0 : offset)}px`;
//         } else {
//             element.style.width = `${total}px`;
//             element.style.left = `${itemRect.left + (offset < 0 ? 0 : offset)}px`;
//         }

//         setVisible(true);
//     };

//     React.useEffect(reflow);

//     return (
//       <SchedulerViewItem
//         {...props}
//         ref={item}
//         style={{
//             visibility: visible ? undefined : "hidden",
//             display: display ? undefined : "none",
//             ...props.style
//         }}
//       />
//     );
// };

// const getRect = (el: HTMLElement | null) => {
//     if (!el) {
//         return { top: 0, left: 0, width: 0, height: 0 };
//     }
//     const top = el.offsetTop;
//     const left = el.offsetLeft;
//     const width = el.offsetWidth;
//     const height = el.offsetHeight;

//     return {
//         top,
//         left,
//         width,
//         height
//     };
// };