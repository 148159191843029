import * as React from 'react';

import { SchedulerHeader, SchedulerHeaderProps } from '@progress/kendo-react-scheduler';
import ProgramContext from './ProgramContext';
import { Link } from 'react-router-dom';

import Slider from './Slider';
interface myState {
    sliderValue:number,

}
interface myProps {
    zoom:IZoom,
    zoomIn: (zoom: IZoom) => void,
    zoomOut: (zoom: IZoom) => void
}
  
export class CustomHeader extends React.Component<SchedulerHeaderProps, myState> {
    static contextType = ProgramContext;
    constructor(props) {
        super(props);
        this.handleSlider = this.handleSlider.bind(this)
        this.state = {
            sliderValue : localStorage.getItem("sliderValue") ? parseInt(localStorage.getItem("sliderValue")) : 5
        }
    }
    handleSlider = (e)=>{
        const newVal = parseInt(e.target.value);
        this.setState({sliderValue: newVal});
        localStorage.setItem("sliderValue", e.target.value)
      }
    render() {
        // k-group-start
        const supports = JSON.parse(localStorage.getItem('support')) || [];
      return (
          <SchedulerHeader>
                <div className="schedulerHeader">
                    <div className="selectDiv">
                        <select value={this.context.selectedSupport} onChange={this.context.selectSupport}>
                            {
                                supports.map((support)=>(
                                    <option key={support._id} value={support.name}>{support.name}</option>
                                    ))
                                    
                                }
                        </select>
                    </div>
                    {this.props.children}
                    <Slider />
                    <div className="logoutButton pull-right">
                        <Link to="/">
                            <button onClick={this.context.logout} className="btn btn-danger">Se Déconnecter</button>
                        </Link>
                    </div>
                </div>
            </SchedulerHeader>

      )
    }
}
