import * as React from 'react';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import products from './products.json';
import {displayDate } from './events-utc';

const group = [];
const data = process(products, {
  group: group
}).data;

export default class ExportScheduler extends React.Component {
  _exporter;
  export = () => {
    this._exporter.save();
  }
  render() {

    var date = displayDate;

    var dates = []
    for (let i = 0; i < 7; i++) {
        dates.push(new Date(date.setDate(date.getDate() + 1)));
    }
    console.log(dates)

    // const columns = [
    //   {
    //     field: 'ProductID',
    //     title: '#'
    //   },
    //   {
    //     field: 'ProductName',
    //     title: 'Product Name'
    //   },
    // ];

    const columnElements = dates.map((column, key) => (
      <ExcelExportColumn
        field={column.toDateString()}
        title={column.toDateString()}
        key={key}
      />
    ));

    return (
      <div>
        <button className="k-button" onClick={this.export}>Export to Excel</button>

        <ExcelExport
          data={data}
          group={group}
          fileName="Products.xlsx"
          ref={(exporter) => { this._exporter = exporter; }}
        >
          {columnElements}
        </ExcelExport>
      </div>
    );
  }
}

