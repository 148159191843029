import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Switch, Route, Redirect } from "react-router-dom";
import Home from './Components/Home';
import ExportScheduler from './Components/ExportScheduler';
import axios from 'axios';
import ProgramContext from './Components/ProgramContext';
import { sampleData } from '../src/Components/events-utc';
import Login from './Components/Login';

export default class App extends Component<any, any>{
  private baseUrl = process.env.REACT_APP_ENV === 'production' ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;
  constructor(props : any){
    super(props);
    this.state = {
      isLoggedIn: true,
      selectedSupport : '',
      sliderValue: localStorage.getItem("sliderValue"),
      programs: localStorage.getItem('program') || [],
      programData: sampleData
    }
  }
   selectSupport = async (event)=>{
     localStorage.setItem('selectedSupport', event.target.value);
    this.setState({selectedSupport: event.target.value})

    await axios.get(`${this.baseUrl}/program/support/${event.target.value}`)
    .then((response: any) => {
      if(response.data.error){
      }else{   
        localStorage.setItem('program', JSON.stringify(response.data.data));
      }
  }).catch((err) => console.log(err.message));

  this.setState({programs: JSON.parse(localStorage.getItem('program'))})
  }
  logout = ()=>{
    localStorage.removeItem('userData');
    this.setState({
      isLoggedIn:false
    })
  }
  handleSlider = (e)=>{
    const newVal = parseInt(e.target.value);
    this.setState({sliderValue: newVal});
    localStorage.setItem("sliderValue", e.target.value)
  }
  render(){
    const contextValue = {
      selectSupport:this.selectSupport,
      handleSlider:this.handleSlider,
      logout: this.logout,
      sliderValue: this.state.sliderValue,
      programs: JSON.parse(localStorage.getItem('program')),
      programData: this.state.programData,
      selectedSupport: localStorage.getItem('selectedSupport') || ''
    }
      return (
        <ProgramContext.Provider value={contextValue}>
        <div className="App">
          <Switch>
            <PrivateRoute exact path="/" component={Home}  />
            <PrivateRoute exact path="/export" component={ExportScheduler} />
            <Route exact path="/login" component={Login} />
          </Switch>
        </div>
        </ProgramContext.Provider>
      );
  }
}
const PrivateRoute = ({ component: Component, ...rest }) => 
(  
  <Route {...rest} render={props => 
  (
    localStorage.getItem('userData') ? <Component {...props} /> : <Redirect to={{pathname: '/login'}}/>
  )}/>
);